.audio-player-container {
    width: 100%;
    max-width: 300px;
}
  
.audio-player {
    height: 50px !important;
}

.chatTextarea {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    outline: none;
    resize: none;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.simple-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 4px 16px; /* Changed 8px to 4px */
    height: 36px;
    width: 120px; /* Fixed width */
    font-size: 16px;
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 10px;
}

.simple-btn:hover {
    background-color: #0056b3;
}

.form-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.form-group label {
    flex-shrink: 0;
    width: 100px;
    margin-right: 1rem;
    font-weight: bold;
}

.form-group input {
    flex-grow: 1;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label-column {
    min-width: 100px;
}

.lineBreak {
    height: 16px; /* Set the height according to your desired spacing */
    width: 100%;
}

.qa-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

.qa-question {
    display: block;
    width: 100%;
    background: #f8f9fa;
    color: #333;
    text-align: left;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
}

.qa-answer {
    padding: 10px;
}

.table-custom-bordered td,
.table-custom-bordered th {
    border: 1px solid #dee2e6;
}

.transcribed-text {
    word-wrap: break-word;
    max-width: 30%; /* Adjust this value based on your layout requirements */
}
  